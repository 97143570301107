import React, { useState, useEffect } from "react";
import { useAuth } from "../Login/AuthProvider";
import { useNavigate } from "react-router-dom";

// Images
import Logo from "../../../Assets/Images/Ostalo/Nk-Podsused-Logo.png";

export default function Logout() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to handle logout and navigation
  const handleLogout = () => {
    logout(); // Call logout function from Auth context
    navigate("/"); // Redirect to the login page
  };

  // Function to open modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Close modal if clicked outside the modal content
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside of the modal
      if (isModalOpen && !event.target.closest(".logout-modal")) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <>
      {/* Logout button */}
      <button className="logout" onClick={openModal}>
        <i className="fi fi-ss-user"></i>
      </button>

      {/* Modal for account information and logout */}
      {isModalOpen && (
        <div className="logout-modal">
          <button className="close-btn" onClick={closeModal}>
            X
          </button>
          <img src={Logo} alt="NK Podsused Logo" className="logo" />
          <h2>ADMIN</h2>
          <h3>NK Podsused</h3>
          <button className="logout-btn" onClick={handleLogout}>
            Odjavi se
          </button>
        </div>
      )}
    </>
  );
}
