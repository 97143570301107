import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./Styles/App.scss";
import {
  Login,
  Home,
  PrivateRoute,
  AuthProvider,
  Logout,
  Footer,
  ParentComponent,
} from "./Components";
import NewsManager from "./Components/Other/News/NewsManager";

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/admin/dashboard"
            element={
              <PrivateRoute>
                <Home />
                <Logout />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/dodaj-novost"
            element={
              <PrivateRoute>
                <ParentComponent />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/ostale-novosti"
            element={
              <PrivateRoute>
                <NewsManager />
              </PrivateRoute>
            }
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </AuthProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
