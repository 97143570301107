import React, { createContext, useState, useContext } from "react";

// Create AuthContext
const AuthContext = createContext();

// Custom hook for easier access to AuthContext
export const useAuth = () => useContext(AuthContext);

// Export AuthProvider as a named export (not default)
export default function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = () => {
    setIsAuthenticated(true); // Set authentication state to true on login
    localStorage.setItem("isAuthenticated", true); // Optionally store the auth state
  };

  const logout = () => {
    console.log("Logging out..."); // Log for debugging
    setIsAuthenticated(false); // Set authentication state to false on logout
    localStorage.removeItem("isAuthenticated"); // Remove the auth state from local storage
    localStorage.removeItem("mode"); // Optionally clear saved mode
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
