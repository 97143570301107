import React, { useState } from "react";
import NewsManager from "./NewsManager";
import AddNews from "./AddNews";

const ParentComponent = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [editingNewsItem, setEditingNewsItem] = useState(null);

  // Function to save new news items
  const handleSaveNewsItem = (newNewsItem) => {
    setNewsItems((prevItems) => {
      const updatedItems = [...prevItems, newNewsItem];
      console.log("Updated News Items:", updatedItems); // Debug log
      return updatedItems;
    });
  };

  // Function to handle editing news items
  const handleEditNewsItem = (index) => {
    setEditingNewsItem({ index, item: newsItems[index] });
  };

  // Function to delete news items
  const handleDeleteNewsItem = (index) => {
    const updatedNewsItems = newsItems.filter((_, i) => i !== index);
    setNewsItems(updatedNewsItems);
  };

  return (
    <div>
      <AddNews
        onSave={handleSaveNewsItem}
        existingItem={editingNewsItem ? editingNewsItem.item : null}
        onCancel={() => setEditingNewsItem(null)}
      />
      <NewsManager
        newsItems={newsItems} // Ensure this is correct
        onEdit={handleEditNewsItem}
        onDelete={handleDeleteNewsItem}
      />
    </div>
  );
};

export default ParentComponent;
