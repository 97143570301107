import { Link } from "react-router-dom";

export default function Footer() {
  let year = new Date().getFullYear();

  return (
    <footer>
      <div className="footer-container">
        <Link
          className="footer-social-links"
          to="https://www.facebook.com/nogometniklubPodsused/?locale=hr_HR"
          target="_blank"
        >
          <i className="fi fi-brands-facebook"></i>
        </Link>
        <Link
          className="footer-social-links"
          to="https://www.instagram.com/nkpodsused/"
          target="_blank"
        >
          <i className="fi fi-brands-instagram"></i>
        </Link>
      </div>
      <div className="footer-bottom">
        <h6>
          &copy; <span>{year}</span> NK Podsused
        </h6>
        <h5>Web izrada: Teo Ivanuš</h5>
      </div>
    </footer>
  );
}
