// NewsPreview.js
import React from "react";

const NewsPreview = ({ article, onPublish }) => {
  if (!article) {
    return <div>Preview nije dostupan.</div>;
  }

  return (
    <div className="news-preview">
      {article.mainImage && (
        <img
          src={URL.createObjectURL(article.mainImage)}
          alt={article.imageAlt}
        />
      )}
      <p>Autor: {article.imageAuthor}</p>
      <span># {article.category}</span>
      <h3>{article.title}</h3>

      {article.introTexts.map((text, index) => (
        <p key={index}>{text}</p>
      ))}

      {article.sections.map((section, index) => (
        <div key={index}>
          {section.image && (
            <img
              src={URL.createObjectURL(section.image)}
              alt={section.imageAlt}
            />
          )}
          <span>{section.imageAuthor}</span>
          <h4>{section.subtitle}</h4>
          <p>{section.text}</p>
          <p>{article.date}</p>
        </div>
      ))}
      <div className="news-form">
        <button className="show-btn publish" onClick={onPublish}>
          Objavi
        </button>{" "}
      </div>
    </div>
  );
};

export default NewsPreview;
