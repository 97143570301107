import Main from "./Main";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import PlavaBG from "../../../Assets/Images/Ostalo/Plava-bg.jpg";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title=""
      />
      <div className="section">
        <h2>ADMIN</h2>

        <div className="home-grid">
          <div className="home-btn">
            <img src={PlavaBG} alt="NK Podsued" />
            <Link to="/admin/dodaj-novost">DODAJ NOVOST</Link>
          </div>
          <div className="home-btn">
            <img src={PlavaBG} alt="NK Podsued" />
            <Link to="/admin/ostale-novosti">OSTALE NOVOSTI</Link>
          </div>
          <div className="home-btn">
            <img src={PlavaBG} alt="NK Podsued" />
            <Link to="https://nkpodsused.pages.dev/">NK PODSUSED</Link>
          </div>
        </div>
      </div>
    </>
  );
}
