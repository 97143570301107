// AddNews.js
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NewsPreview from "./NewsPreview";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import Main from "../Home/Main";

const AddNews = ({ onSave }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const newsItem = location.state?.newsItem; // Retrieve passed news data

  // State management
  const [mainImage, setMainImage] = useState(
    newsItem ? newsItem.mainImage : null
  );
  const [imageAlt, setImageAlt] = useState(newsItem ? newsItem.imageAlt : "");
  const [imageAuthor, setImageAuthor] = useState(
    newsItem ? newsItem.imageAuthor : ""
  );
  const [category, setCategory] = useState(
    newsItem ? newsItem.category : "Odaberite"
  );
  const [title, setTitle] = useState(newsItem ? newsItem.title : "");
  const [introTexts, setIntroTexts] = useState(
    newsItem ? newsItem.introTexts : [""]
  );
  const [sections, setSections] = useState(newsItem ? newsItem.sections : []);
  const [newsAuthor, setNewsAuthor] = useState(newsItem ? newsItem.author : "");
  const [date, setDate] = useState(newsItem ? newsItem.date : "");
  const [previewData, setPreviewData] = useState(null);

  // Category options
  const categories = [
    "Odaberite",
    "Novosti sa utakmica",
    "Novosti",
    "Zanimljivosti",
    "Ostalo",
  ];

  const addIntroText = () => {
    setIntroTexts([...introTexts, ""]);
  };

  const removeIntroText = (index) => {
    if (introTexts.length > 1) {
      const updatedIntroTexts = introTexts.filter((_, i) => i !== index);
      setIntroTexts(updatedIntroTexts);
    }
  };

  const addSection = () => {
    setSections([
      ...sections,
      { image: null, imageAlt: "", imageAuthor: "", subtitle: "", text: "" },
    ]);
  };

  const removeSection = (index) => {
    const updatedSections = sections.filter((_, i) => i !== index);
    setSections(updatedSections);
  };

  const handlePreview = () => {
    setPreviewData({
      title,
      date,
      author: newsAuthor,
      mainImage,
      imageAlt,
      imageAuthor,
      category,
      introTexts,
      sections,
    });
  };

  const handlePublish = () => {
    const newNewsItem = {
      mainImage,
      imageAlt,
      imageAuthor,
      category,
      title,
      introTexts,
      sections,
      newsAuthor,
      date,
    };

    // Call the onSave function to save the news item
    onSave(newNewsItem); // Ensure this is correctly called

    // Reset the form and navigate
    resetForm();
    navigate("/admin/ostale-novosti"); // This navigates to NewsManager
  };

  const resetForm = () => {
    setMainImage(null);
    setImageAlt("");
    setImageAuthor("");
    setCategory("Odaberite");
    setTitle("");
    setIntroTexts([""]);
    setSections([]);
    setNewsAuthor("");
    setDate("");
    setPreviewData(null);
  };

  useEffect(() => {
    // If newsItem exists, pre-fill form data
    if (newsItem) {
      setMainImage(newsItem.mainImage);
      setImageAlt(newsItem.imageAlt);
      setImageAuthor(newsItem.imageAuthor);
      setCategory(newsItem.category);
      setTitle(newsItem.title);
      setIntroTexts(newsItem.introTexts);
      setSections(newsItem.sections);
      setNewsAuthor(newsItem.author);
      setDate(newsItem.date);
    }
  }, [newsItem]);

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title=""
      />
      <div className="section">
        <button
          className="go-back-btn"
          onClick={() => navigate("/admin/dashboard")}
        >
          Vrati se nazad
        </button>
        <h2>{newsItem ? "Uredi Novost" : "Dodaj Novost"}</h2>

        <div className="news-form">
          <label>Glavna slika:</label>
          <input
            className="news-img"
            type="file"
            accept="image/*" // Accept only images
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                setMainImage(file);
              }
            }}
          />
          <div className="news-img-form">
            <input
              type="text"
              placeholder="Opis slike (alt)"
              value={imageAlt}
              onChange={(e) => setImageAlt(e.target.value)}
            />
            <input
              type="text"
              placeholder="Autor slike"
              value={imageAuthor}
              onChange={(e) => setImageAuthor(e.target.value)}
            />
          </div>
        </div>

        <div className="news-form">
          <label>Kategorija:</label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            {categories.map((cat, index) => (
              <option key={index} value={cat} disabled={cat === "Odaberite"}>
                {cat}
              </option>
            ))}
          </select>
        </div>

        <div className="news-form">
          <label>Glavni naslov:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Glavni naslov"
          />
        </div>

        <div className="news-form">
          {introTexts.map((text, index) => (
            <div key={index}>
              <input
                type="text"
                value={text}
                placeholder="Uvodni tekst"
                onChange={(e) => {
                  const updatedIntroTexts = [...introTexts];
                  updatedIntroTexts[index] = e.target.value;
                  setIntroTexts(updatedIntroTexts);
                }}
              />
              {index > 0 && ( // Remove button only on subsequent texts
                <button
                  className="news-btn remove-btn"
                  onClick={() => removeIntroText(index)}
                >
                  Obriši
                </button>
              )}
            </div>
          ))}
          <button className="news-btn" onClick={addIntroText}>
            Dodaj uvodni tekst
          </button>
        </div>

        <div className="news-form">
          {sections.map((section, index) => (
            <div key={index}>
              <input
                type="file"
                accept="image/*" // Accept only images
                onChange={(e) => {
                  const updatedSections = [...sections];
                  const file = e.target.files[0];
                  if (file) {
                    updatedSections[index].image = file;
                    setSections(updatedSections);
                  }
                }}
              />
              <div className="alt-input">
                <input
                  type="text"
                  placeholder="Opis slike"
                  value={section.imageAlt}
                  onChange={(e) => {
                    const updatedSections = [...sections];
                    updatedSections[index].imageAlt = e.target.value;
                    setSections(updatedSections);
                  }}
                />
                <input
                  type="text"
                  placeholder="Autor slike"
                  value={section.imageAuthor}
                  onChange={(e) => {
                    const updatedSections = [...sections];
                    updatedSections[index].imageAuthor = e.target.value;
                    setSections(updatedSections);
                  }}
                />
              </div>
              <input
                type="text"
                placeholder="Podnaslov"
                value={section.subtitle}
                onChange={(e) => {
                  const updatedSections = [...sections];
                  updatedSections[index].subtitle = e.target.value;
                  setSections(updatedSections);
                }}
              />
              <textarea
                placeholder="Tekst"
                value={section.text}
                onChange={(e) => {
                  const updatedSections = [...sections];
                  updatedSections[index].text = e.target.value;
                  setSections(updatedSections);
                }}
              />
              <button
                className="news-btn remove-btn"
                onClick={() => removeSection(index)}
              >
                Obriši sekciju
              </button>
            </div>
          ))}
          <button className="news-btn" onClick={addSection}>
            Dodaj sekciju
          </button>
        </div>

        <div className="news-form">
          <label>Autor novosti:</label>
          <input
            type="text"
            value={newsAuthor}
            onChange={(e) => setNewsAuthor(e.target.value)}
          />
        </div>

        <div className="news-form">
          <label>Datum:</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            onFocus={(e) => e.target.showPicker()}
          />
        </div>

        <div className="news-form">
          <button className="show-btn" onClick={handlePreview}>
            Prikaži pregled
          </button>
        </div>

        {previewData && (
          <NewsPreview article={previewData} onPublish={handlePublish} />
        )}
      </div>
    </>
  );
};

export default AddNews;
