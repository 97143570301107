import React from "react";

const NewsManager = ({ newsItems, onEdit, onDelete }) => {
  console.log("News Items in NewsManager:", newsItems); // Debug log

  return (
    <div>
      <h2>Ostale Novosti</h2>
      {newsItems.length === 0 ? (
        <p>Nema dostupnih novosti.</p>
      ) : (
        <ul>
          {newsItems.map((item, index) => (
            <li key={index}>
              <h3>{item.title}</h3>
              <button onClick={() => onEdit(index)}>Uredi</button>
              <button onClick={() => onDelete(index)}>Obriši</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NewsManager;
